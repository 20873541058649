var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('el-row',{staticClass:"banner"}),_c('el-row',{staticClass:"advantage"},[_c('el-col',{staticClass:"advantage_title"},[_c('span',[_vm._v(_vm._s(_vm.$t('lang.advantage')))])]),_c('el-col',{staticClass:"advantage_content"},_vm._l((_vm.advantageList),function(item){return _c('el-row',{key:item.id,staticClass:"everyLine"},[_c('el-col',{staticClass:"advantage_pic",attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('div',{staticClass:"img_box"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.image),expression:"item.image"}],attrs:{"alt":""}})])]),_c('el-col',{staticClass:"advantage_word",attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('h3',[_vm._v(" "+_vm._s(_vm.language == 'cn' ? item.cn_title : _vm.language == 'jp' ? item.jp_title : _vm.language == 'en' ? item.en_title : '')+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.language == 'cn' ? item.cn_content : _vm.language == 'jp' ? item.jp_content : _vm.language == 'en' ? item.en_content : '')+" ")])])],1)}),1)],1),_c('el-row',{staticClass:"service"},[_c('el-col',{staticClass:"service_title"},[_c('span',[_vm._v(_vm._s(_vm.$t('lang.service')))])]),_c('el-col',{attrs:{"xs":16,"sm":16,"md":16,"lg":16,"xl":16}},_vm._l((_vm.serviceList),function(item){return _c('el-row',{key:item.id,staticClass:"service_content"},[_c('el-col',{staticClass:"service_pic",attrs:{"xs":4,"sm":4,"md":4,"lg":4,"xl":4}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.image),expression:"item.image"}],attrs:{"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.language == 'cn' ? item.cn_title : _vm.language == 'jp' ? item.jp_title : _vm.language == 'en' ? item.en_title : ''))])]),_c('el-col',{attrs:{"xs":2,"sm":2,"md":2,"lg":2,"xl":2}},[_vm._v(" ")]),_c('el-col',{staticClass:"service_item",attrs:{"xs":18,"sm":18,"md":18,"lg":18,"xl":18}},[_c('ul',_vm._l((JSON.parse(
                _vm.language == 'cn'
                  ? item.cn_content
                  : _vm.language == 'jp'
                  ? item.jp_content
                  : _vm.language == 'en'
                  ? item.en_content
                  : ''
              )),function(itemm,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(itemm)+" ")])}),0)])],1)}),1)],1),_c('el-row',{staticClass:"cooperation"},[_c('el-col',{staticClass:"cooperation_title"},[_c('span',[_vm._v(_vm._s(_vm.$t('lang.cooperation')))])]),_c('el-col',{staticClass:"cooperation_content",attrs:{"xs":16,"sm":16,"md":16,"lg":16,"xl":16}},_vm._l((_vm.cooperationList),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item.title))])}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="container">
    <el-row class="banner">
    </el-row>
    <el-row class="advantage">
      <el-col class="advantage_title">
        <span>{{ $t('lang.advantage') }}</span>
      </el-col>
      <el-col class="advantage_content">
        <el-row v-for="item in advantageList" :key="item.id" class="everyLine">
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
            class="advantage_pic"
          >
            <div class="img_box"><img v-lazy="item.image" alt="" /></div>
          </el-col>
          <el-col
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
            class="advantage_word"
          >
            <h3>
              {{
                language == 'cn'
                  ? item.cn_title
                  : language == 'jp'
                  ? item.jp_title
                  : language == 'en'
                  ? item.en_title
                  : ''
              }}
            </h3>
            <p>
              {{
                language == 'cn'
                  ? item.cn_content
                  : language == 'jp'
                  ? item.jp_content
                  : language == 'en'
                  ? item.en_content
                  : ''
              }}
            </p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="service">
      <el-col class="service_title">
        <span>{{ $t('lang.service') }}</span>
      </el-col>
      <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
        <el-row
          v-for="item in serviceList"
          :key="item.id"
          class="service_content"
        >
          <el-col class="service_pic" :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
            <img v-lazy="item.image" alt="" />
            <span>{{
              language == 'cn'
                ? item.cn_title
                : language == 'jp'
                ? item.jp_title
                : language == 'en'
                ? item.en_title
                : ''
            }}</span>
          </el-col>
          <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2">&nbsp;</el-col>
          <el-col
            class="service_item"
            :xs="18"
            :sm="18"
            :md="18"
            :lg="18"
            :xl="18"
          >
            <ul>
              <li
                v-for="(itemm, index) in JSON.parse(
                  language == 'cn'
                    ? item.cn_content
                    : language == 'jp'
                    ? item.jp_content
                    : language == 'en'
                    ? item.en_content
                    : ''
                )"
                :key="index"
              >
                {{ itemm }}
              </li>
            </ul>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="cooperation">
      <el-col class="cooperation_title">
        <span>{{ $t('lang.cooperation') }}</span>
      </el-col>
      <el-col
        class="cooperation_content"
        :xs="16"
        :sm="16"
        :md="16"
        :lg="16"
        :xl="16"
      >
        <span v-for="(item, index) in cooperationList" :key="index">{{
          item.title
        }}</span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import '../../node_modules/jquery-backstretch/jquery.backstretch.js'
import { homeBanner, homeResource, sourceChannel } from '../api/index'
export default {
  name: 'home',
  props: {},
  data() {
    return {
      language: window.sessionStorage.getItem('language')
        ? window.sessionStorage.getItem('language')
        : 'jp',
      bannerList: [],
      advantageList: [],
      serviceList: [],
      cooperationList: []
    }
  },
  computed: {},
  created() {
    this.getBannerList()
    this.getAdvantageList()
    this.getServiceList()
    this.getPartnerList()
  },
  mounted() {},
  watch: {
    data(value) {
      console.log(value)
    }
  },
  methods: {
    getBannerList() {
      homeBanner({ type: 1 })
        .then(res => {
          if (res.code == '20000') {
            res.data.list.forEach(item => {
              this.bannerList.push(item.image)
            })
            // console.log(this.bannerList)
            this.$nextTick(() => {
              $('.banner').backstretch(this.bannerList, {
                fade: 1500
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getAdvantageList() {
      homeResource({ type: 1 })
        .then(res => {
          if (res.code == '20000') {
            this.advantageList = res.data.list
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getServiceList() {
      homeResource({ type: 2 })
        .then(res => {
          if (res.code == '20000') {
            this.serviceList = res.data.list
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getPartnerList() {
      sourceChannel()
        .then(res => {
          if (res.code == '20000') {
            this.cooperationList = res.data.list
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    goAboutPage() {
      this.$router.push({ name: 'about' })
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .container {
    width: 100%;
    .banner {
      position: relative;
      width: 100%;
      height: 699px;
      .world {
        position: absolute;
        top: 414px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color: #ffffff;
        font-size: 48px;
        font-weight: 500;
      }
      .more {
        position: absolute;
        top: 476px;
        left: 50%;
        transform: translateX(-50%);
        height: 34px;
        line-height: 34px;
        text-align: center;
        width: 130px;
        border-radius: 7px;
        color: #ffffff;
        font-size: 16px;
        background-color: #bc3810;
        cursor: pointer;
      }
    }
    .advantage {
      height: 1754px;
      background-color: #fdfdfd;
      .advantage_title {
        height: 188px;
        padding-top: 85px;
        text-align: center;
        span {
          height: 38px;
          color: #2a2a2a;
          font-size: 36px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 38px;
        }
      }
      .advantage_content {
        .everyLine:nth-of-type(1),
        .everyLine:nth-of-type(3) {
          height: 300px;
          margin-bottom: 80px;
          .advantage_pic {
            height: 100%;
            .img_box {
              float: right;
              overflow: hidden;
              width: 600px;
              height: 100%;
              img {
                width: 600px;
                height: 100%;
                transition: all 0.3s ease !important;
                &:hover {
                  transform: scale(1.2);
                }
              }
            }
          }
          .advantage_word {
            padding: 95px 0 0 100px;
            h3 {
              margin: 0;
              color: #000000;
              font-size: 28px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 38px;
            }
            p {
              width: 380px;
              color: #000000;
              font-size: 16px;
              letter-spacing: 0;
              line-height: 26px;
            }
          }
        }
        .everyLine:nth-of-type(2),
        .everyLine:nth-of-type(4) {
          height: 300px;
          margin-bottom: 80px;
          .advantage_pic {
            float: right;
            height: 100%;
            .img_box {
              overflow: hidden;
              width: 600px;
              height: 100%;
              img {
                width: 600px;
                height: 100%;
                transition: all 0.3s ease !important;
                &:hover {
                  transform: scale(1.2);
                }
              }
            }
          }
          .advantage_word {
            padding: 95px 100px 0 0;
            h3 {
              text-align: right;
              margin: 0;
              color: #000000;
              font-size: 28px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 38px;
            }
            p {
              float: right;
              width: 380px;
              color: #000000;
              font-size: 16px;
              letter-spacing: 0;
              line-height: 26px;
            }
          }
        }
      }
    }
    .service {
      width: 100%;
      background-color: rgba(209, 201, 199, 0.25);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 116px;
      .service_title {
        text-align: center;
        margin: 70px 0;
        span {
          height: 38px;
          color: #000000;
          font-size: 36px;
          font-weight: 500;
          line-height: 38px;
        }
      }
      .service_content {
        padding: 17px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #979797;
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
        .service_pic {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            height: 60px;
            width: 65px;
            margin-bottom: 12px;
          }
          span {
            margin: 0;
            height: 38px;
            color: #000000;
            font-size: 24px;
            font-weight: 500;
            line-height: 38px;
          }
        }
        .service_item {
          // margin-left: 92px;
          li {
            float: left;
            margin-left: 80px;
            list-style-type: disc;
            // height: 38px;
            color: #000000;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 38px;
          }
        }
      }
    }
    .cooperation {
      padding-bottom: 66px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background-color: #fdfdfd;
      .cooperation_title {
        margin: 68px 0 81px 0;
        text-align: center;
        height: 38px;
        color: #2a2a2a;
        font-size: 36px;
        font-weight: 500;
        line-height: 38px;
      }
      .cooperation_content {
        span {
          margin-right: 40px;
          height: 38px;
          color: #2a2a2a;
          font-size: 24px;
          font-weight: 500;
          line-height: 38px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .container {
    width: 100%;
    .banner {
      width: 100%;
      height: 409px;
    }
    .bannerWord {
      display: flex;
      flex-direction: column;
      align-items: center;
      .world {
        margin: 22px 0 16px 0;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color: #000000;
        font-size: 22px;
        font-weight: 500;
      }
      .more {
        height: 30px;
        width: 82px;
        border-radius: 7px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
        font-size: 12px;
        background-color: #bc3810;
        cursor: pointer;
      }
    }

    .advantage {
      background-color: #fdfdfd;
      .advantage_title {
        margin: 50px 0 12px 0;
        text-align: center;
        span {
          height: 38px;
          color: #2a2a2a;
          font-size: 20px;
          font-weight: 600;
          line-height: 38px;
        }
      }
      .advantage_content {
        .everyLine {
          padding: 0 37px;
          .advantage_pic {
            height: 160px;
            .img_box {
              width: 100%;
              height: 100%;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                transition: all 0.3s ease !important;
                &:hover {
                  transform: scale(1.2);
                }
              }
            }
          }
          .advantage_word {
            margin: 12px 0 24px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            h3 {
              margin: 0;
              color: #000000;
              font-size: 16px;
              font-weight: 500;
              line-height: 38px;
            }
            p {
              margin: 0;
              color: #000000;
              font-size: 12px;
              line-height: 26px;
            }
          }
        }
      }
    }
    .service {
      width: 100%;
      background-color: rgba(209, 201, 199, 0.25);
      display: flex;
      flex-direction: column;
      align-items: center;
      // margin-bottom: 118px;
      .service_title {
        text-align: center;
        margin: 20px 0 28px 0;
        span {
          width: 100px;
          color: #2a2a2a;
          font-size: 20px;
          font-weight: 500;
          line-height: 38px;
        }
      }
      .service_content {
        padding: 17px 0;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #979797;
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
        .service_pic {
          display: flex;
          align-items: center;
          width: 100%;
          img {
            height: 20px;
            width: 20px;
          }
          span {
            margin-left: 10px;
            color: #000000;
            font-size: 16px;
            font-weight: 500;
            line-height: 38px;
          }
        }
        .service_item {
          width: 100%;
          li {
            list-style-type: disc;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
            color: #000000;
            margin-bottom: 12px;
          }
        }
      }
    }
    .cooperation {
      padding: 50px 0 45px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background-color: #fdfdfd;
      .cooperation_title {
        margin-bottom: 10px;
        text-align: center;
        height: 38px;
        color: #2a2a2a;
        font-size: 20px;
        font-weight: 500;
        line-height: 38px;
      }
      .cooperation_content {
        span {
          margin-right: 12px;
          color: #2a2a2a;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 26px;
        }
      }
    }
  }
}
</style>
